import { GetStaticPropsContext } from "next";
import Head from "next/head";
import Link from "next/link";
import { useTranslations } from "next-intl";

import { LoginAd } from "@shared/components";
import { PicterContestsLogo, Text } from "@shared/elements";
import { useAd } from "@shared/hooks";
import { VideoFile } from "@shared/types";

import AuthenticationForm from "src/components/AuthenticationForm";
import BrandsGridDisplay from "src/components/BrandsGridDisplay";
import DefaultOpenGraphTags from "src/components/DefaultOpenGraphTags";

import * as constants from "src/constants";
import { paths } from "src/constants";
import bundleMessages from "src/utils/bundleMessages";


/**
 * This route wraps the AuthenticationForm component.
 * Which enables the user to login or signup via password or code.
 *
 * But we have multiple user groups which require different styles and texts
 * when loging in or signing up.
 * Therefore we extracted the login and signup logic into a seperate component and
 * wrap this component in multiple routes with custom designs and features.
 */
export default function LogIn() {
  const t = useTranslations(`${constants.MESSAGE_PREFIX}pages.login`);
  const { ad: loginAd } = useAd("Login Screen");

  return (
    <>
      <Head>
        <title key="title">Picter Contests – Login</title>
        <DefaultOpenGraphTags />
      </Head>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Link href={paths.index}>
                <PicterContestsLogo />
              </Link>
              <Text as="h2" size="3xl" className="mt-6" weight="bold">
                {t("title")}
              </Text>
              <Text className="mt-2" color="muted" size="sm">
                {t.rich("orSignUp", {
                  // eslint-disable-next-line react/display-name
                  link: (text) => (
                    <Link
                      href={paths.signup}
                      className="font-medium text-primary-600 hover:text-primary-500"
                    >
                      {text}
                    </Link>
                  ),
                })}
              </Text>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <AuthenticationForm />
              </div>
            </div>
          </div>
        </div>
        {loginAd !== undefined &&
          (loginAd !== null ? (
            <div className="hidden lg:block relative w-0 flex-1 bg-gray-100 lg:px-10">
              <LoginAd
                key={loginAd.id}
                campaign={loginAd.campaign}
                impressionUrl={loginAd.impressionUrl}
                clickUrl={loginAd.clickUrl}
                type={loginAd.content.mediaType}
                src={loginAd.content.files[0].src}
                poster={
                  loginAd.content.mediaType === "video"
                    ? (loginAd.content.files[0] as VideoFile).poster
                    : undefined
                }
              />
            </div>
          ) : (
            <BrandsGridDisplay />
          ))}
      </div>
    </>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  const messages = await bundleMessages(locale, [
    `${constants.MESSAGE_PREFIX}pages.login`,
    ...AuthenticationForm.messages,
    ...LoginAd.messages,
    ...BrandsGridDisplay.messages,
  ]);
  return {
    props: {
      messages,
    },
  };
}
